<template>
  <div>
    <!-- User details -->
    <v-row v-if="user" no-gutters class="pb-2">
      <v-col cols="3" class="d-flex align-center">
        <span>Role</span>
      </v-col>
      <v-col class="d-flex align-center">
        <span>{{ user.role }}</span>
      </v-col>
    </v-row>
    <v-row v-if="user" no-gutters class="pb-2">
      <v-col cols="3" class="d-flex align-center">
        <span>Email</span>
      </v-col>
      <v-col class="d-flex align-center">
        <span>{{ user.email }}</span>
      </v-col>
    </v-row>
    <v-row v-if="user" no-gutters class="pb-2">
      <v-col cols="3" class="d-flex align-center">
        <span>Phone</span>
      </v-col>
      <v-col class="d-flex align-center">
        <span>{{ user.phone }}</span>
      </v-col>
    </v-row>
    <v-row v-if="user" no-gutters class="pb-2">
      <v-col cols="3" class="d-flex align-center">
        <span>Alternative Phone</span>
      </v-col>
      <v-col class="d-flex align-center">
        <span>{{ user.phone2 }}</span>
      </v-col>
    </v-row>

    <v-row v-if="user" no-gutters>
      <v-col cols="3" class="d-flex align-center">
        <span>Language</span>
      </v-col>
      <v-col class="d-flex align-center">
        <v-avatar size="24" class="me-2 my-1">
          <v-img
            :src="
              user.language.toLowerCase() === 'english'
                ? require('@/assets/usa_flag.png')
                : require('@/assets/col_flag.png')
            "
          />
        </v-avatar>
      </v-col>
    </v-row>
    <v-divider class="my-4" />
  </div>
</template>

<script>
export default {
  name: "UserGeneralInfo",
  props: {
    id: String,
    user: Object
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
.text-singleline {
  line-height: 0.75rem;
}
</style>
